<template>
	<div class="tw-hidden lg:tw-block">
		<!--- Navigation Links --->
		<span
			@click="showAbout = !showAbout"
			v-click-outside="() => (closeDropdown('about'))"
			class="tw-px-3 tw-py-2 tw-rounded-md tw-text-sm tw-font-medium"
			:class="showAbout ? 'tw-font-bold tw-text-blue-200' : 'tw-text-blue-200 hover:tw-opacity-75 tw-cursor-pointer'"
		>
			About
			<svg
				class="tw-ml-1 tw-h-5 tw-w-5 tw-inline"
				:class="showAbout ? 'tw-font-bold tw-text-white' : 'tw-text-gray-500'"
				x-description="Heroicon name: solid/chevron-down"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor"
				aria-hidden="true"
			>
				<path
					fill-rule="evenodd"
					d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
					clip-rule="evenodd"
				/>
			</svg>
		</span>
		<transition
			enter-active-class="tw-transition ease-out duration-200"
			enter-class="tw-opacity-0 tw-transform tw-scale-95"
			enter-to-class="tw-opacity-100 tw-transform tw-translate-y"
			leave-active-class="tw-transition tw-ease-in tw-duration-150"
			leave-class="tw-opacity-100 tw-translate-y-0"
			leave-to-class="tw-opacity-0 tw-y-1"
		>
			<div
				v-if="showAbout"
				class="tw-absolute tw-z-10 tw-mt-6 tw-px-2 tw-w-screen tw-max-w-xs sm:tw-px-0"
			>
				<div class="tw-rounded-lg tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-overflow-hidden">
					<div class="tw-relative tw-grid tw-gap-6 tw-bg-white tw-px-5 tw-py-6 sm:tw-gap-8 sm:tw-p-8">
						<a
							href="/about"
							class="tw--m-3 tw-p-3 tw-block tw-rounded-md hover:tw-bg-gray-50 tw-transition tw-ease-in-out tw-duration-150"
						>
							<p class="tw-text-base tw-font-medium tw-text-gray-900">
								About
							</p>
							<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
								Get to know everything about FORGEBOX and its editions.
							</p>
						</a>

						<a
							href="https://ortussolutions.com/products/commandbox"
							class="tw--m-3 tw-p-3 tw-block tw-rounded-md hover:tw-bg-gray-50 tw-transition tw-ease-in-out tw-duration-150"
							rel="noopener"
							target="_blank"
						>
							<p class="tw-text-base tw-font-medium tw-text-gray-900">
								Download CommandBox
							</p>
							<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
								Visit CommandBox offical site and download the app.
							</p>
						</a>

						<a
							href="https://forgebox.ortusbooks.com"
							class="tw--m-3 tw-p-3 tw-block tw-rounded-md hover:tw-bg-gray-50 tw-transition tw-ease-in-out tw-duration-150"
							rel="noopener"
							target="_blank"
						>
							<p class="tw-text-base tw-font-medium tw-text-gray-900">
								Documentation
							</p>
							<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
								Find all you need to get started and the full documentation to become an expert.
							</p>
						</a>

						<a
							href="https://github.com/ortus-solutions/commandbox"
							class="tw--m-3 tw-p-3 tw-block tw-rounded-md hover:tw-bg-gray-50 tw-transition tw-ease-in-out tw-duration-150"
							rel="noopener"
							target="_blank"
						>
							<p class="tw-text-base tw-font-medium tw-text-gray-900">
								Source Code
							</p>
							<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
								Do you want to contribute? Visit our GitHub and help us grow and develop new features.
							</p>
						</a>

						<a
							href="https://www.ortussolutions.com/blog/category/forgebox"
							class="tw--m-3 tw-p-3 tw-block tw-rounded-md hover:tw-bg-gray-50 tw-transition tw-ease-in-out tw-duration-150"
							rel="noopener"
							target="_blank"
						>
							<p class="tw-text-base tw-font-medium tw-text-gray-900">
								News
							</p>
							<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
								Get up-to-date with the latest news and blog posts.
							</p>
						</a>
					</div>
				</div>
			</div>
		</transition>
		<span
			@click="showSupport = !showSupport"
			v-click-outside="() => (closeDropdown('support'))"
			class="tw-px-3 tw-py-2 tw-rounded-md tw-text-sm tw-font-medium"
			:class="showSupport ? 'tw-font-bold tw-text-blue-200' : 'tw-text-blue-200 hover:tw-opacity-75 tw-cursor-pointer'"
		>
			Support
			<svg
				class="tw-ml-1 tw-h-5 tw-w-5 tw-text-gray-500 tw-inline"
				x-description="Heroicon name: solid/chevron-down"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor"
				aria-hidden="true"
			>
				<path
					fill-rule="evenodd"
					d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
					clip-rule="evenodd"
				/>
			</svg>
		</span>
		<transition
			enter-active-class="tw-transition ease-out duration-200"
			enter-class="tw-opacity-0 tw-transform tw-scale-95"
			enter-to-class="tw-opacity-100 tw-transform tw-translate-y"
			leave-active-class="tw-transition tw-ease-in tw-duration-150"
			leave-class="tw-opacity-100 tw-translate-y-0"
			leave-to-class="tw-opacity-0 tw-y-1"
		>
			<div
				v-if="showSupport"
				class="tw-absolute tw-z-10 tw-mt-6 tw-px-2 tw-w-screen tw-max-w-xs sm:tw-px-0"
			>
				<div class="tw-rounded-lg tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-overflow-hidden">
					<div class="tw-relative tw-grid tw-gap-6 tw-bg-white tw-px-5 tw-py-6 sm:tw-gap-8 sm:tw-p-8">
						<a
							href="https://ortussolutions.com/services/training"
							class="tw--m-3 tw-p-3 tw-block tw-rounded-md hover:tw-bg-gray-50 tw-transition tw-ease-in-out tw-duration-150"
							rel="noopener"
							target="_blank"
						>
							<p class="tw-text-base tw-font-medium tw-text-gray-900">
								Professional Training
							</p>
							<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
								Get trained by the actual makers of the Box products.
							</p>
						</a>

						<a
							href="https://ortussolutions.com/services/support"
							class="tw--m-3 tw-p-3 tw-block tw-rounded-md hover:tw-bg-gray-50 tw-transition tw-ease-in-out tw-duration-150"
							rel="noopener"
							target="_blank"
						>
							<p class="tw-text-base tw-font-medium tw-text-gray-900">
								Professional Support
							</p>
							<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
								Ortus offer a variety of consulting packages & support plans.
							</p>
						</a>

						<a
							href="https://www.cfcasts.com"
							class="tw--m-3 tw-p-3 tw-block tw-rounded-md hover:tw-bg-gray-50 tw-transition tw-ease-in-out tw-duration-150"
							rel="noopener"
							target="_blank"
						>
							<p class="tw-text-base tw-font-medium tw-text-gray-900">
								CFCasts
							</p>
							<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
								Learn from short format videos on all things related to modern web development.
							</p>
						</a>

						<a
							href="/support"
							class="tw--m-3 tw-p-3 tw-block tw-rounded-md hover:tw-bg-gray-50 tw-transition tw-ease-in-out tw-duration-150"
							rel="noopener"
							target="_blank"
						>
							<p class="tw-text-base tw-font-medium tw-text-gray-900">
								Contact Us
							</p>
							<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
								Have something to say or report. Send us a message.
							</p>
						</a>
					</div>
				</div>
			</div>
		</transition>
		<span
			@click="showRSS = !showRSS"
			v-click-outside="() => (closeDropdown('rss'))"
			class="tw-px-3 tw-py-2 tw-rounded-md tw-text-sm tw-font-medium"
			:class="showRSS ? 'tw-font-bold tw-text-blue-200' : 'tw-text-blue-200 hover:tw-opacity-75 tw-cursor-pointer'"
		>
			RSS
			<svg
				class="tw-ml-1 tw-h-5 tw-w-5 tw-text-gray-500 tw-inline"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor"
				aria-hidden="true"
			>
				<path
					fill-rule="evenodd"
					d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
					clip-rule="evenodd"
				/>
			</svg>
		</span>
		<transition
			enter-active-class="tw-transition ease-out duration-200"
			enter-class="tw-opacity-0 tw-transform tw-scale-95"
			enter-to-class="tw-opacity-100 tw-transform tw-translate-y"
			leave-active-class="tw-transition tw-ease-in tw-duration-150"
			leave-class="tw-opacity-100 tw-translate-y-0"
			leave-to-class="tw-opacity-0 tw-y-1"
		>
			<div
				v-if="showRSS"
				class="tw-absolute tw-z-10 tw-mt-6 tw-px-2 tw-w-screen tw-max-w-xs sm:tw-px-0"
			>
				<div class="tw-rounded-lg tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-overflow-hidden">
					<div class="tw-relative tw-grid tw-gap-6 tw-bg-white tw-px-5 tw-py-6 sm:tw-gap-8 sm:tw-p-8">
						<a
							href="/rss/sort/popular"
							class="tw--m-3 tw-p-3 tw-block tw-rounded-md hover:tw-bg-gray-50 tw-transition tw-ease-in-out tw-duration-150"
							rel="noopener"
							target="_blank"
						>
							<p class="tw-text-base tw-font-medium tw-text-gray-900">
								Most Popular
							</p>
						</a>

						<a
							href="/rss/sort/installs"
							class="tw--m-3 tw-p-3 tw-block tw-rounded-md hover:tw-bg-gray-50 tw-transition tw-ease-in-out tw-duration-150"
							rel="noopener"
							target="_blank"
						>
							<p class="tw-text-base tw-font-medium tw-text-gray-900">
								Most Installs
							</p>
						</a>

						<a
							href="/rss/sort/new"
							class="tw--m-3 tw-p-3 tw-block tw-rounded-md hover:tw-bg-gray-50 tw-transition tw-ease-in-out tw-duration-150"
							rel="noopener"
							target="_blank"
						>
							<p class="tw-text-base tw-font-medium tw-text-gray-900">
								Newest
							</p>

						</a>

						<a
							href="/rss/sort/recent"
							class="tw--m-3 tw-p-3 tw-block tw-rounded-md hover:tw-bg-gray-50 tw-transition tw-ease-in-out tw-duration-150"
							rel="noopener"
							target="_blank"
						>
							<p class="tw-text-base tw-font-medium tw-text-gray-900">
								Recently Updated
							</p>
						</a>
					</div>
				</div>
			</div>
		</transition>
		<a
			href="/plans"
			class="tw-px-3 tw-py-2 tw-rounded-md tw-text-sm tw-font-medium tw-text-blue-200 hover:tw-text-blue-200 hover:tw-opacity-75"
		>
			Plans
		</a>
		<a
			href="/stats"
			class="tw-px-3 tw-py-2 tw-rounded-md tw-text-sm tw-font-medium tw-text-blue-200 hover:tw-text-blue-200 hover:tw-opacity-75"
		>
			Stats
		</a>
	</div>
</template>
<script>

import ClickOutside from "vue-click-outside";

export default {
	data() {
		return {
			showAbout   : false,
			showSupport : false,
			showRSS     : false
		};
	},
	directives : { ClickOutside },
	computed   : {},
	methods    : {
		closeDropdown( dropdown ) {
			var app = this;
			switch ( dropdown ) {
			case "about":
				app.showAbout = false;
				break;
			case "support":
				app.showSupport = false;
				break;
			case "rss":
				app.showRSS = false;
				break;
			}
		}
	}
};
</script>
